import { useMemo } from "react"
import { useOutletContext } from "react-router-dom"

import getExportColumns from "./getExportColumns"

const useExportColumns = () => {
  const {
    project = {},
    getAccount,
    getTierName,
    getSponsorName,
    bankAccountsMap,
    computeUserFullname,
  } = useOutletContext()

  return useMemo(() => getExportColumns({
    project,
    getAccount,
    getTierName,
    getSponsorName,
    bankAccountsMap,
    computeUserFullname
  }), [
    project,
    getAccount,
    getTierName,
    getSponsorName,
    bankAccountsMap,
    computeUserFullname
  ])
}

export default useExportColumns
