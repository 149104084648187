import { getAddress } from "@components/Address"
import { formatPhoneNumber } from "@components/Text"
import { getUsDateFromIsoDate } from "@components/Date"
import { getFormattedDecimalValue, getFormattedAmountValue } from "@components/Amount"
import {
  isTrustInvestment,
  isSdiraInvestment,
  isJointInvestment,
  isEntityInvestment,
  isIndividualInvestment,
  computeInvestmentAmount
} from "@components/Investment"
import {
  PROJECT_TYPES,
  ENTITY_TYPE_LLC,
  PROFILE_TYPES_LABELS,
  ENTITY_TYPE_TITLES_MAP,
  INVESTMENT_PROFILE_FIELD_MAP,
  ENTITY_TYPE_EXEMPT_ORGANIZATION,
  JOINT_MEMBERSHIP_INTERESTS_TITLES_MAP,
  ENTITY_MEMBERSHIP_INTERESTS_TITLES_MAP,
  ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_LLC,
  ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_IRA,
  BENEFICIAL_OWNER_TAX_FILING_STATUS_TITLES_MAP,
  ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_TITLES_MAP,
} from "@components/Domain"

import getBeneficiary from "./getBeneficiary"
import getEntityNames from "./getEntityNames"
import getAccreditation from "./getAccreditation"


const getTotalByTier = (tier, investments) => {
  const amountValue = investments
    .filter(({ projectTierId }) => projectTierId === tier.name)
    .map(({ amount }) => amount)
    .reduce((_, a) => getFormattedDecimalValue(_, a, "plus"), 0)

  return getFormattedAmountValue(amountValue)
}

const residenceValue = ({ isUsPerson, isUsCitizen, hasOtherUsResidenceState, residenceState }, addressState) => {
  const computedResidenceState = hasOtherUsResidenceState
    ? residenceState
    : addressState

  if (isUsCitizen) {
    return computedResidenceState
  }

  if (!isUsPerson) {
    return "Not a resident of US"
  }

  return computedResidenceState
}

const getAccreditationValue = accreditationValue => {
  const isAccreditation = accreditationValue === "Yes"
  const isNoAccreditation = accreditationValue === "No"

  if (isNoAccreditation) {
    return "No Accreditation"
  }

  if (isAccreditation) {
    return "Accreditation Verified"
  }

  return "Unverified Accreditation"
}

const getExportColumns = ({
  project,
  getAccount,
  getTierName,
  getSponsorName,
  bankAccountsMap,
  computeUserFullname
}) => {
  const {
    type: projectType,
    name: projectName,
    state: projectState,
    tiers: projectTiers,
    closeDate: projectCloseDate,
  } = project

  const isNonFund = projectType !== PROJECT_TYPES.FUND

  const getInvestmentClasses = () => {
    return projectTiers.map(tier => {
      return {
        title: getTierName(tier),
        value: ({ investments }) => getTotalByTier(tier, investments)
      }
    })
  }

  return [
    {
      title: "Project Name",
      value: () => projectName
    },
    {
      title: "Entity Name(s)",
      value: () => getEntityNames(project)
    },
    {
      title: "Beneficiary",
      value: () => getBeneficiary(bankAccountsMap, project)
    },
    {
      title: "Sponsor",
      value: ({ sponsorId }) => {
        return getSponsorName(sponsorId)
      }
    },
    {
      title: "Liaison",
      value: ({ brokerUserId }) => {
        return computeUserFullname(brokerUserId)
      }
    },
    {
      title: "Investment ID",
      value: ({ id }) => id,
    },
    {
      title: "Investment Profile Name",
      value: ({ name }) => name,
    },
    {
      title: "Investor Legal Full Name",
      value: ({ investorAccountId }) => {
        const account = getAccount(investorAccountId)
        const { investor } = account
        const { givenName, familyName, additionalName } = investor

        return [ givenName, additionalName, familyName ].join(" ")
      }
    },
    {
      title: "DOB",
      value: ({ investorAccountId }) => {
        const account = getAccount(investorAccountId)
        const { investor } = account
        return investor.dateOfBirth
          ? getUsDateFromIsoDate(investor.dateOfBirth)
          : ""
      }
    },
    {
      title: "Mailing Address",
      value: ({ investorAccountId }) => {
        const account = getAccount(investorAccountId)
        const { investor } = account
        return getAddress(investor.address)
      }
    },
    {
      title: "Contact Phone Number",
      value: ({ investorAccountId }) => {
        const account = getAccount(investorAccountId)
        const { investor } = account
        return formatPhoneNumber(investor.mobileNumber)
      }
    },
    {
      title: "Email Address",
      value: ({ investorAccountId }) => {
        const account = getAccount(investorAccountId)
        const { investor } = account
        return investor.email
      }
    },
    {
      title: "Additional Details",
      value: investment => {
        const { isFunded, investor, profileType } = investment
        const { hasAccreditation, accreditationExpiresAt } = investor

        const {
          value: accreditationValue
        } = getAccreditation(hasAccreditation, accreditationExpiresAt, projectCloseDate, projectState, isFunded, isNonFund)

        const accreditation = getAccreditationValue(accreditationValue)

        const profile = investment[INVESTMENT_PROFILE_FIELD_MAP[profileType]]

        if (!profile) {
          return accreditation
        }

        const { isUsCitizen } = profile

        const hasUsCitizenValue = isUsCitizen !== undefined

        if (!hasUsCitizenValue) {
          return accreditation
        }

        const citizenship = isUsCitizen
          ? "is US citizen"
          : "is not US citizen"

        return [ accreditation, citizenship ].join(", ")
      }
    },
    {
      title: "Investment Profile Type",
      value: ({ profileType }) => PROFILE_TYPES_LABELS[profileType] || "",
    },
    {
      title: "Title of membership interests",
      value: investment => {
        const { profileIndividual, profileJoint, profileEntity } = investment

        if (isIndividualInvestment(investment)) {
          if (!profileIndividual) {
            return
          }

          const { isMarriedAndSeparateProperty } = profileIndividual

          return isMarriedAndSeparateProperty
            ? "A married person, as my sole and separate property"
            : "Individually, as a single person"
        }

        if (isJointInvestment(investment)) {
          if (!profileJoint) {
            return
          }

          const { membershipInterestsTitle } = profileJoint

          return JOINT_MEMBERSHIP_INTERESTS_TITLES_MAP[membershipInterestsTitle]
        }

        if (isEntityInvestment(investment)) {
          if (!profileEntity) {
            return
          }

          const { membershipInterestsTitle } = profileEntity

          return ENTITY_MEMBERSHIP_INTERESTS_TITLES_MAP[membershipInterestsTitle]?.replace("As ", "")
        }

        return "-"
      }
    },
    {
      title: "SSN/ITIN",
      value: investment => {
        const { profileJoint, profileIndividual } = investment

        if (isIndividualInvestment(investment)) {
          if (!profileIndividual) {
            return
          }

          return profileIndividual.ssnNumber
        }

        if (isJointInvestment(investment)) {
          if (!profileJoint) {
            return
          }

          return profileJoint.ssnNumber
        }

        return "-"
      }
    },
    {
      title: "Co-Investor Legal Full Name",
      value: investment => {
        if (!isJointInvestment(investment)) {
          return "-"
        }

        const { profileJoint } = investment

        if (!profileJoint) {
          return
        }

        const {
          jointGivenName,
          jointFamilyName,
          jointAdditionalName,
        } = profileJoint

        return [ jointGivenName, jointAdditionalName, jointFamilyName ].join(" ")
      }
    },
    {
      title: "Co-Investor Email",
      value: investment => {
        if (!isJointInvestment(investment)) {
          return "-"
        }

        const { profileJoint } = investment

        if (!profileJoint) {
          return
        }

        const { shouldCoInvestorSign } = profileJoint

        if (!shouldCoInvestorSign) {
          return "-"
        }

        return profileJoint.jointEmail
      }
    },
    {
      title: "Joint Address",
      value: investment => {
        if (!isJointInvestment(investment)) {
          return "-"
        }

        const { profileJoint } = investment

        if (!profileJoint) {
          return
        }

        return getAddress(profileJoint.jointAddress)
      }
    },
    {
      title: "Entity's Full Legal Name",
      value: investment => {
        if (!isEntityInvestment(investment)) {
          return "-"
        }

        if (isTrustInvestment(investment)) {
          return "-"
        }

        const { profileEntity } = investment

        if (!profileEntity) {
          return
        }

        return profileEntity.name
      }
    },
    {
      title: "Trust's Full Legal Name",
      value: investment => {
        if (!isEntityInvestment(investment)) {
          return "-"
        }

        if (!isTrustInvestment(investment)) {
          return "-"
        }

        const { profileEntity } = investment

        if (!profileEntity) {
          return
        }

        return profileEntity.name
      }
    },
    {
      title: "EIN",
      value: investment => {
        if (!isEntityInvestment(investment)) {
          return "-"
        }

        const { profileEntity } = investment

        if (!profileEntity) {
          return
        }

        return profileEntity.einNumber
      }
    },
    {
      title: "Entity Type",
      value: investment => {
        if (!isEntityInvestment(investment)) {
          return "-"
        }

        const { profileEntity } = investment

        if (!profileEntity) {
          return
        }

        const { entityType, isDisregardedForTaxPurposes } = profileEntity

        if (isDisregardedForTaxPurposes) {
          return "-"
        }

        return ENTITY_TYPE_TITLES_MAP[entityType]
      }
    },
    {
      title: "Tax Filing Status",
      value: investment => {
        if (!isEntityInvestment(investment)) {
          return "-"
        }

        const { profileEntity } = investment

        if (!profileEntity) {
          return
        }

        const {
          entityType,
          isDisregardedForTaxPurposes,
          beneficialOwnerTaxFilingStatus
        } = profileEntity

        const isLLC = entityType === ENTITY_TYPE_LLC

        if (!isLLC) {
          return "-"
        }

        if (isDisregardedForTaxPurposes) {
          return "-"
        }

        return (
          beneficialOwnerTaxFilingStatus
            ? BENEFICIAL_OWNER_TAX_FILING_STATUS_TITLES_MAP[beneficialOwnerTaxFilingStatus]
            : ""
        )
      }
    },
    {
      title: "Retirement Plan (IRA/SEP/Keogh/etc.)",
      value: investment => {
        if (!isEntityInvestment(investment)) {
          return "-"
        }

        const { profileEntity } = investment

        if (!profileEntity) {
          return
        }

        const {
          entityType,
          isRetirementPlan
        } = profileEntity

        const isExemptOrganization = entityType === ENTITY_TYPE_EXEMPT_ORGANIZATION

        if (!isExemptOrganization) {
          return "-"
        }

        const hasRetirementPlanValue = isRetirementPlan !== undefined

        if (!hasRetirementPlanValue) {
          return ""
        }

        return isRetirementPlan
          ? "Yes"
          : "No"
      }
    },
    {
      title: "Status (e.g. Disregarded or not)",
      value: investment => {
        if (!isEntityInvestment(investment)) {
          return "-"
        }

        const { profileEntity } = investment

        if (!profileEntity) {
          return
        }

        const { isDisregardedForTaxPurposes } = profileEntity

        const hasDisregardedForTaxPurposesValue = isDisregardedForTaxPurposes !== undefined

        if (!hasDisregardedForTaxPurposesValue) {
          return ""
        }

        return isDisregardedForTaxPurposes
          ? "Disregarded for federal income tax purposes"
          : "Not disregarded for federal income tax purposes"
      }
    },
    {
      title: "Beneficial Owner Name",
      value: investment => {
        if (!isEntityInvestment(investment)) {
          return "-"
        }

        const { profileEntity } = investment

        if (!profileEntity) {
          return
        }

        const { isDisregardedForTaxPurposes } = profileEntity

        return isDisregardedForTaxPurposes
          ? profileEntity.beneficialOwnerName
          : "-"
      }
    },
    {
      title: "Beneficial Owner Entity Type",
      value: investment => {
        if (!isEntityInvestment(investment)) {
          return "-"
        }

        const { profileEntity } = investment

        if (!profileEntity) {
          return
        }

        const { isDisregardedForTaxPurposes } = profileEntity

        if (!isDisregardedForTaxPurposes) {
          return "-"
        }

        const { beneficialOwnerEntityType } = profileEntity

        return beneficialOwnerEntityType
          ? ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_TITLES_MAP[beneficialOwnerEntityType]
          : ""
      }
    },
    {
      title: "Beneficial Owner Tax Filing Status",
      value: investment => {
        if (!isEntityInvestment(investment)) {
          return "-"
        }

        const { profileEntity } = investment

        if (!profileEntity) {
          return
        }

        const {
          beneficialOwnerEntityType,
          isDisregardedForTaxPurposes,
          beneficialOwnerTaxFilingStatus,
        } = profileEntity

        const isLLC = beneficialOwnerEntityType === ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_LLC

        if (!isLLC) {
          return "-"
        }

        if (!isDisregardedForTaxPurposes) {
          return "-"
        }

        return beneficialOwnerTaxFilingStatus
          ? BENEFICIAL_OWNER_TAX_FILING_STATUS_TITLES_MAP[beneficialOwnerTaxFilingStatus]
          : ""
      }
    },
    {
      title: "Beneficial Owner (Custodian) EIN",
      value: investment => {
        if (!isEntityInvestment(investment)) {
          return "-"
        }

        const { profileEntity } = investment

        if (!profileEntity) {
          return
        }

        const {
          beneficialOwnerEntityType,
          isDisregardedForTaxPurposes
        } = profileEntity

        const isIRA = beneficialOwnerEntityType === ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_IRA

        if (!isIRA) {
          return "-"
        }

        return isDisregardedForTaxPurposes
          ? profileEntity.beneficialOwnerEinNumber
          : "-"
      }
    },
    {
      title: "Beneficial Owner SSN/ITIN",
      value: investment => {
        if (!isEntityInvestment(investment)) {
          return "-"
        }

        const { profileEntity } = investment

        if (!profileEntity) {
          return
        }

        const {
          beneficialOwnerEntityType,
          isDisregardedForTaxPurposes
        } = profileEntity

        const isIRA = beneficialOwnerEntityType === ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_IRA

        if (isIRA) {
          return "-"
        }

        return isDisregardedForTaxPurposes
          ? profileEntity.beneficialOwnerSsnNumber
          : "-"
      }
    },
    {
      title: "Beneficial Owner Address",
      value: investment => {
        if (!isEntityInvestment(investment)) {
          return "-"
        }

        const { profileEntity } = investment

        if (!profileEntity) {
          return
        }

        const { isDisregardedForTaxPurposes } =  profileEntity

        return isDisregardedForTaxPurposes
          ? getAddress(profileEntity.beneficialOwnerAddress)
          : "-"
      }
    },
    {
      title: "Full Legal SDIRA Account Name",
      value: investment => {
        if (!isSdiraInvestment(investment)) {
          return "-"
        }

        const { profileSdira } = investment

        if (!profileSdira) {
          return
        }

        return profileSdira.accountName
      }
    },
    {
      title: "Custodian Name",
      value: investment => {
        if (!isSdiraInvestment(investment)) {
          return "-"
        }

        const { profileSdira } = investment

        if (!profileSdira) {
          return
        }

        return profileSdira.custodianName
      }
    },
    {
      title: "Custodian EIN",
      value: investment => {
        if (!isSdiraInvestment(investment)) {
          return "-"
        }

        const { profileSdira } = investment

        if (!profileSdira) {
          return
        }

        return profileSdira.custodianEinNumber
      }
    },
    {
      title: "Custodian Address",
      value: investment => {
        if (!isSdiraInvestment(investment)) {
          return "-"
        }

        const { profileSdira } = investment

        if (!profileSdira) {
          return
        }

        return getAddress(profileSdira.custodianAddress)
      }
    },
    {
      title: "State of Residence",
      value: investment => {
        let region = null
        let profile = null

        const {
          investor,
          profileJoint,
          profileSdira,
          profileEntity,
          profileIndividual,
        } = investment

        if (isIndividualInvestment(investment) && profileIndividual) {
          profile = profileIndividual

          const { address } = investor
          region = address?.addressRegion
        }

        if (isJointInvestment(investment) && profileJoint) {
          profile = profileJoint

          region = (profileJoint.jointAddress || {}).addressRegion
        }

        if (isEntityInvestment(investment) && profileEntity) {
          profile = profileEntity

          const { address, beneficialOwnerAddress } = profileEntity
          region = address?.addressRegion || beneficialOwnerAddress?.addressRegion
        }

        if (isSdiraInvestment(investment) && profileSdira) {
          profile = profileSdira

          const { address } = investor
          region = address?.addressRegion
        }

        if (profile) {
          return residenceValue(profile, region)
        }

        return ""
      }
    },
    {
      title: "Non-US Person",
      value: investment => {
        const { profileType } = investment

        if (isSdiraInvestment(investment)) {
          return "No"
        }

        const profile = investment[INVESTMENT_PROFILE_FIELD_MAP[profileType]]

        if (!profile) {
          return ""
        }

        const { isUsPerson, isUsCitizen } = profile

        if (isUsCitizen) {
          return "No"
        }

        const hasUsPersonValue = isUsPerson !== undefined

        if (!hasUsPersonValue) {
          return ""
        }

        if (isUsPerson) {
          return "No"
        }

        return "Yes"
      }
    },
    ...getInvestmentClasses(),
    {
      title: "Distribution Account Mask",
      value: ({ receiverSourceName }) => receiverSourceName
    },
    {
      title: "Subscription Status",
      value: ({ isSigned }) =>
        isSigned
          ? "SIGNED"
          : "PENDING"
    },
    {
      title: "Total Committed",
      value: investment => getFormattedAmountValue(computeInvestmentAmount(investment))
    },
    {
      title: "Funds Received",
      value: ({ receivedAmount }) => getFormattedAmountValue(receivedAmount)
    }
  ]
}

export default getExportColumns
